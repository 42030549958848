// src/constants/terms.js

export const termsContent = [
  {
  name: "Terms and Conditions",
  description: "By using the services provided by BharatMandi, you acknowledge that you have read, understood, and agreed to comply with the following terms and conditions."
  },
  {
  name: "Acceptance of Terms",
  description: "By accessing or using the services provided by BharatMandi, you agree to comply with and be bound by these terms and conditions."
  },
  {
  name: "Cancellation Policy",
  description: "By using the services of BharatMandi, customers agree to adhere to the cancellation policies outlined below.",
  subSections: [
  {
  name: "Cancellation by Customer",
  description: "Customers have the right to cancel their order up to the cut-off time of the slot for which the order was placed. To cancel an order, customers must contact customer service at +91-7892721984. Upon cancellation, any payments already made will be credited to the customer's wallet or bank account within 7 working days."
  },
  {
  name: "Fraudulent Transactions",
  description: "Bharatmandi reserves the right to cancel any order suspected of fraudulent activity or violating the terms and conditions of the website usage. A negative list of fraudulent transactions and customers will be maintained, and access may be denied to such customers."
  }
  ]
  },
  {
  name: "Return and Refund Policy",
  description: "By using the services of BharatMandi, customers agree to adhere to the return and refund policies outlined below.",
  subSections: [
  {
  name: "Return Policy",
  description: "All items to be returned or exchanged must be unused and in their original condition with all original labels and packaging intact, and should not be broken or tampered within the return period. Exchanges are allowed only if your address is serviceable for an Exchange by our logistics team. If you choose to self-ship your returns, kindly pack the items securely to prevent any loss or damage during transit. Perishable goods such as seeds cannot be returned & are eligible for refunds. Products must be returned within 15 days from the day of delivery. To return your purchase, please call customer care at +91-7892721984."
  },
  {
  name: "Refund Process",
  description: "Refunds for returned products will be processed through the same online mode within 15 working days. The credited amount will reflect in the customer’s account within 7-10 working days after processing, depending on the payment gateway or bank processing times."
  }
  ]
  },
  {
  name: "General",
  description: "BharatMandi reserves the right to modify or terminate the services for any reason, without notice, at any time. Users agree to use the services at their own risk, and BharatMandi shall not be responsible for any damages or losses. Users are responsible for maintaining the confidentiality of their account information and passwords."
  },
  {
  name: "Contact Information",
  description: "For any questions or concerns regarding these terms and conditions, please contact customer service at +91-7892721984."
  }
  ];
  
  