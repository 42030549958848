import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "./productDetails.scss";
// import Arrow from "assets/images/Arrow";

// Import product images from their respective directories
import C6maxProduct1 from "assets/images/products/c6max-product.png";

import CarbonlitesProduct1 from "assets/images/products/carbonlites-product.png";

import SoilSpectraProduct1 from "assets/images/products/soil-spectra-product-image1.jpg";
import SoilSpectraProduct2 from "assets/images/products/soil-spectra-product-image2.jpg";
import SoilSpectraProduct3 from "assets/images/products/soil-spectra-product-image3.jpg";
import SoilSpectraProduct4 from "assets/images/products/soil-spectra-product-image4.jpg";
import SoilSpectraProduct5 from "assets/images/products/soil-spectra-product-image5.jpg";
import SoilSpectraProduct6 from "assets/images/products/soil-spectra-product-image6.jpg";
import SoilSpectraProduct7 from "assets/images/products/soil-spectra-product-image7.jpg";

import SoilHydraProduct1 from "assets/images/products/soil-hydra-product-image1.png";

import SoilHydraPlusProduct1 from "assets/images/products/soil-hydra-plus-product-image1.png";

import MilkTrakProduct1 from "assets/images/products/milktrak-product-image1.png";
import MilkTrakProduct2 from "assets/images/products/milktrak-product-image2.png";
import MilkTrakProduct3 from "assets/images/products/milktrak-product-image3.png";
import MilkTrakProduct4 from "assets/images/products/milktrak-product-image4.png";

const productData = [
  {
    id: "c6-max",
    name: "C6 Max",
    images: [
      { url: C6maxProduct1, alt: "C6 Max Front View" },
    ],
    category: "Agri Inputs",
    shortDescription: "C6 Max is an organic manure which helps to reduce reliance on synthetic fertilizers, minimises soil degradation, promotes a balanced and resilient soil ecosystem.",
    description: `
      <h2>C6 Max Organic Manure</h2>
      <p>C6 Max is an organic manure which helps to reduce reliance on synthetic fertilizers, minimises soil degradation, promotes a balanced and resilient soil ecosystem.</p>
      
      <h3>Key Benefits</h3>
      <ul>
        <li>Reduces dependency on chemical fertilizers</li>
        <li>Improves soil structure and fertility</li>
        <li>Enhances nutrient uptake</li>
        <li>Promotes beneficial microbial growth</li>
      </ul>

      <h3>Application Guidelines</h3>
      <p>Apply 2-3 kg per acre, mixed well with soil before sowing. For best results, maintain adequate soil moisture after application.</p>

      <h3>Storage Instructions</h3>
      <p>Store in a cool, dry place away from direct sunlight. Keep container tightly sealed when not in use.</p>
    `,
    features: [
      "Reduces reliance on synthetic fertilizers",
      "Minimizes soil degradation",
      "Promotes balanced soil ecosystem",
      "Eco-friendly approach",
      "Sustainable agriculture solution"
    ],
    specifications: {
      "Composition": "Organic materials and beneficial microorganisms",
      "Application Rate": "2-3 kg per acre",
      "Packaging": "Available in 5kg and 10kg bags",
      "Shelf Life": "2 years from date of manufacture",
      "Certification": "Organic certified"
    },
    productUrl: "https://www.bharatmandi.com/products/c6-max",
  },
  {
    id: "carbonlites",
    name: "Carbonlites",
    images: [
      { url: CarbonlitesProduct1, alt: "Carbonlites Front View" },
    ],
    category: "Agri Inputs",
    shortDescription: "Carbonlites Organic Manure is IMO and FCO Certified and fully organic. It has a balanced blend of macro and micro nutrients essential for plant vitality.",
    description: `
      <h2>Carbonlites Organic Manure</h2>
      <p>Carbonlites Organic Manure is IMO and FCO Certified and fully organic. It has a balanced blend of macro and micro nutrients essential for plant vitality.</p>
      
      <h3>Key Benefits</h3>
      <ul>
        <li>IMO and FCO Certified</li>
        <li>Balanced nutrient blend</li>
        <li>Enhanced soil health</li>
        <li>Improved yield quality</li>
        <li>Contains microbial consortia</li>
      </ul>

      <h3>Application Guidelines</h3>
      <p>Apply 2-4 kg per acre, mixed well with soil before sowing. For best results, maintain adequate soil moisture after application.</p>

      <h3>Storage Instructions</h3>
      <p>Store in a cool, dry place away from direct sunlight. Keep container tightly sealed when not in use.</p>
    `,
    features: [
      "IMO and FCO Certified",
      "Balanced nutrient blend",
      "Enhanced soil health",
      "Improved yield quality",
      "Contains microbial consortia"
    ],
    specifications: {
      "Composition": "Nutrient-rich digestate with biomass",
      "Application Rate": "2-4 kg per acre",
      "Packaging": "Available in multiple sizes",
      "Shelf Life": "18 months from manufacture",
      "Certification": "IMO and FCO certified"
    },
    productUrl: "https://www.bharatmandi.com/products/carbonlites",
  },
  {
    id: "soil-spectra",
    name: "SOIL SPECTRA 3.0",
    images: [
      { url: SoilSpectraProduct1, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct2, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct3, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct4, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct5, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct6, alt: "SOIL SPECTRA 3.0" },
      { url: SoilSpectraProduct7, alt: "SOIL SPECTRA 3.0" }
    ],
    category: "Soil Testing",
    shortDescription: "SOIL SPECTRA 3.0 provides an instant comprehensive analysis of crucial soil parameters along with a completely customized POP tailored to optimize your crop nutrition. Empowering you to optimize yields, promote sustainable growth, and streamline your cultivation costs.",
    description: `
      <h2>SOIL SPECTRA 3.0 Soil Testing Kit</h2>
      <p>The SOIL SPECTRA 3.0 is your ultimate companion for precise cultivation. With advanced soil testing capabilities, this device empowers farmers to optimize yields, enhance sustainability, and minimize cultivation costs. Its compact, splashproof design makes it easy to use and durable in various agricultural settings.</p>

      <h3>Key Benefits</h3>
      <ul>
        <li>Instant soil test reports delivered directly to WhatsApp</li>
        <li>Comprehensive soil analysis for NPK, temperature, moisture, pH, organic carbon (OC), and electrical conductivity (EC)</li>
        <li>Waterproof and durable probe built with high-grade stainless steel and nylon</li>
        <li>Customized soil test reports with crop-specific fertilizer recommendations</li>
        <li>GeoFencing and live weather integration for precise farming</li>
        <li>Supports BLE and Wi-Fi connectivity</li>
        <li>Machine learning-based results with continuous improvement</li>
      </ul>

      <h3>Usage Guidelines</h3>
      <p>To use the SOIL SPECTRA 3.0, insert the probe into the soil at the desired location. Follow the on-screen instructions provided in the Farmsys eFarm NPK Android app to capture accurate soil data. The device is designed to work seamlessly with geo-location and weather information for a comprehensive analysis.</p>

      <h3>Storage Instructions</h3>
      <p>Store the device in a cool, dry place in the provided carrying case. Ensure the sensors and circuitry remain clean and protected when not in use to maintain longevity and accuracy.</p>

      <h3>Compatibility</h3>
      <p>The SOIL SPECTRA 3.0 is compatible with the Farmsys eFarm NPK Android application, offering seamless data integration and report generation. The app also provides additional features like farm geo-location tracking and weather updates.</p>
    `,
    features: [
      "Instant soil analysis with results under 30 seconds",
      "Comprehensive testing of over 5 essential soil parameters",
      "Customized crop-specific fertilizer recommendations",
      "Durable and waterproof construction",
      "BLE and Wi-Fi compatibility for real-time data sync",
      "Compact and splashproof design"
    ],
    specifications: {
      "Analysis Time": "< 30 seconds",
      "Parameters Tested": "NPK, temperature, moisture, pH, organic carbon (OC), electrical conductivity (EC)",
      "Report Format": "Digital and printable reports, available on WhatsApp",
      "Technology": "Advanced spectroscopy with ML-based analytics",
      "Connectivity": "Supports BLE and Wi-Fi integration with the Farmsys eFarm NPK app"
    },
    productUrl: "https://farmsys.co/soilspectra.html"
  },
  {
    id: "soil-hydra",
    name: "Soil Hydra",
    images: [
      { url: SoilHydraProduct1, alt: "Soil Hydra Sensor" }
    ],
    category: "Soil Testing",
    shortDescription: "The Soil Hydra Moisture Sensor delivers real-time, precise soil moisture data, revolutionizing irrigation and resource management with anti-corrosion technology for long-lasting use.",
    description: `
      <h2>Soil Hydra Moisture Sensor</h2>
      <p>The Soil Hydra Moisture Sensor delivers real-time, precise soil moisture data to revolutionize irrigation and resource management. By utilizing cutting-edge capacitive sensing technology, it measures volumetric water content in soil with outstanding accuracy. Its corrosion-resistant ceramic design ensures long-lasting performance and reliability.</p>

      <h3>Key Benefits</h3>
      <ul>
        <li>Affordable and cost-effective</li>
        <li>Durable and long-lasting design</li>
        <li>Real-time monitoring of soil moisture content</li>
        <li>Corrosion-resistant ceramic sensors for reliable performance</li>
        <li>Easy to install and use</li>
      </ul>

      <h3>How It Works</h3>
      <p>The Soil Hydra uses advanced capacitive sensing to detect changes in soil water content. The sensor converts these changes into voltage signals, providing accurate moisture levels for better decision-making. Its compact design allows for easy insertion into soil, making it ideal for various agricultural and gardening applications.</p>

      <h3>Usage Guidelines</h3>
      <p>Insert the sensor vertically into the soil near plant roots to monitor moisture levels effectively. Ensure the sensor is fully inserted for accurate readings. Refer to the user manual for detailed installation and usage instructions.</p>

      <h3>Storage Instructions</h3>
      <p>Store the sensor in a cool, dry place away from direct sunlight and moisture. Ensure the sensor and its components are properly sealed when not in use to maintain longevity.</p>
    `,
    features: [
      "Affordable and durable design",
      "Real-time soil moisture monitoring",
      "Corrosion-resistant ceramic sensors",
      "Easy installation and usability",
      "Compatible with low-voltage microcontrollers (3.3V and 5.5V)"
    ],
    specifications: {
      "Interface": "Analog 3-wire cable",
      "Size": "48mm x 12.2mm x 0.8mm",
      "Frame and Enclosure": "Ceramic, Glass, ABS",
      "Output Signal": "DC 1.2-2.2V (constant output for all input voltages)",
      "Input Supply": "DC 3.3-5.5V"
    },
    productUrl: "https://farmsys.co/soilhydra.html"
  },
  {
    id: "soil-hydra-plus",
    name: "Soil Hydra+",
    images: [
      { url: SoilHydraPlusProduct1, alt: "Soil Hydra+ Device" },
    ],
    category: "Soil Testing",
    shortDescription: "The Soil Hydra+ 4-in-1 sensor delivers instant and precise monitoring of soil moisture, temperature, EC, and pH levels, enabling smarter agriculture practices.",
    description: `
      <h2>Soil Hydra+ 4-in-1 Sensor</h2>
      <p>The Soil Hydra+ (4-in-1 sensor) is a state-of-the-art tool designed for modern agricultural needs. By simultaneously measuring pH, EC, moisture, and temperature with high precision, it provides farmers with real-time soil insights, promoting better decision-making for sustainable farming.</p>
      
      <h3>Key Benefits</h3>
      <ul>
        <li>High precision and sensitivity for reliable data</li>
        <li>RS485 connectivity for enhanced integration</li>
        <li>Replaceable probes for long-term use</li>
        <li>Fully sealed waterproof design (IP68 rated)</li>
        <li>Multi-sensor integration for simultaneous measurements</li>
        <li>Real-time data logging with advanced analytics</li>
      </ul>

      <h3>Applications</h3>
      <p>The Soil Hydra+ is suitable for diverse applications, including:</p>
      <ul>
        <li>Scientific experiments</li>
        <li>Water-saving irrigation</li>
        <li>Greenhouses and gardening</li>
        <li>Precision agriculture</li>
        <li>Environmental monitoring in outdoor farming</li>
      </ul>

      <h3>Usage Guidelines</h3>
      <p>Install the sensor in the soil following the user manual's detailed instructions. Ensure proper placement for accurate data collection. Compatible with Modbus software for seamless operation.</p>

      <h3>Storage Instructions</h3>
      <p>Store the sensor in a cool, dry place. Keep it sealed in its protective casing when not in use to maintain its durability and accuracy.</p>
    `,
    features: [
      "Simultaneous measurement of moisture, temperature, EC, and pH",
      "High precision and sensitivity",
      "RS485 connectivity and Modbus support",
      "Replaceable probes",
      "Fully sealed, waterproof design (IP68 rated)",
      "Real-time data logging and analytics"
    ],
    specifications: {
      "Parameters": "Moisture, Temperature, EC, pH",
      "Soil Temperature Range": "-40°C to 150°C",
      "pH Range": "4 (Acidic) to 9 (Alkaline)",
      "Soil Moisture Range": "0% - 100%",
      "Electrical Conductivity": "0 - 4 mS/cm",
      "Frame and Enclosure": "Resin, SS316, ABS",
      "Interface": "4-wire (RS485)",
      "Software": "Modbus",
      "Input Supply": "5.5V to 12V DC"
    },
    productUrl: "https://farmsys.co/soilhydra4in1.html",
  },
  {
    id: "milktrak",
    name: "MilkTrak",
    images: [
      { url: MilkTrakProduct1, alt: "Testing Kit Components" },
      { url: MilkTrakProduct2, alt: "Sample Analysis" },
      { url: MilkTrakProduct3, alt: "Results Display" },
      { url: MilkTrakProduct4, alt: "Results Display" }
    ],
    category: "Quality Testing",
    shortDescription: "MilkTrak™ offers a comprehensive and affordable solution for detecting multiple adulterants in milk. It empowers dairy companies with quick, accurate testing and traceability to enhance milk quality.",
    description: `
      <h2>MilkTrak Adulterant Test Kit</h2>
      <p>MilkTrak Adulterant Test Kit is a cutting-edge solution for rapid and reliable detection of common milk adulterants. Designed to meet the demands of modern dairy testing, MilkTrak ensures high-quality milk while simplifying the testing process.</p>

      <h3>Key Benefits</h3>
      <ul>
        <li>Affordable and accessible testing</li>
        <li>Rapid detection of over 10 common adulterants</li>
        <li>Single-strip testing for multiple parameters</li>
        <li>Portable and easy to use</li>
        <li>Developed in collaboration with IIT Madras</li>
      </ul>

      <h3>How It Works</h3>
      <p>The MilkTrak test kit uses advanced paper-based strips to detect adulterants. Simply dip the strip in a milk sample, and use the MilkTrak App to capture and analyze the results instantly. The app provides accurate, quantitative data for each parameter tested.</p>

      <h3>Complete Traceability</h3>
      <p>MilkTrak goes beyond testing by offering a digital platform to track results in real time. This helps reduce risks, improve milk quality, and provide transparency throughout the supply chain.</p>

      <h3>Storage and Handling Instructions</h3>
      <p>Store the kit in a cool, dry place away from direct sunlight. Ensure the container is tightly sealed when not in use to maintain optimal performance.</p>
    `,
    features: [
      "Quick, simple, and reliable testing",
      "Single-strip testing for multiple adulterants",
      "Affordable and user-friendly design",
      "Instant digital results with the MilkTrak App",
      "Backed by world-class research and development"
    ],
    specifications: {
      "Test Time": "< 60 seconds",
      "Detectable Adulterants": "10+ common adulterants",
      "Kit Contents": "Testing strips, reagents, manual",
      "Shelf Life": "6 months"
    },
    productUrl: "https://www.milktrak.in"
  }
];

const ProductDetails = () => {
  const { productId } = useParams();
  
  // Find product by ID
  const product = productData.find(p => p.id === productId);
  
  const [selectedImage, setSelectedImage] = useState(0);
  const [currentTab, setCurrentTab] = useState("description");
  const [formState, setFormState] = useState({
    ProductName: product?.name || "", 
    CustomerName: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
    Message: "",
  });

  if (!product) {
    return (
      <div className="product-details-page">
        <div className="navigation-bar">
          <div className="container">
            <Link to="/Products" className="back-link">
              <FaArrowLeft className="back-arrow" /> Back to Products
            </Link>
          </div>
        </div>
        <div className="container">
          <h2>Product not found</h2>
        </div>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formState.CustomerName || !formState.PhoneNumber) {
      alert("Please fill in your name and phone number before submitting.");
      return;
    }

    try {
      const updatedFormState = {
        ...formState,
        ProductName: product.name
      };

      setFormState(prevState => ({
        ...prevState,
        submitted: true
      }));

      console.log("Form submitted:", updatedFormState);
      
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting the form. Please try again.");
    }
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="product-details-page">
      <div className="navigation-bar">
        <div className="container">
          <Link to="/Products" className="back-link">
            <FaArrowLeft className="back-arrow" /> Back to Products
          </Link>
        </div>
      </div>

      <div className="product-details-container">
        <div className="container">
          <div className="product-main">
            <div className="product-gallery">
              <div className="main-image">
                <img 
                  src={product.images[selectedImage].url} 
                  alt={product.images[selectedImage].alt}
                />
              </div>
              <div className="thumbnail-list">
                {product.images.map((image, index) => (
                  <div 
                    key={index}
                    className={`thumbnail ${selectedImage === index ? "active" : ""}`}
                    onClick={() => setSelectedImage(index)}
                  >
                    <img src={image.url} alt={image.alt} />
                  </div>
                ))}
              </div>
            </div>

            <div className="product-info">
              <div className="category-tag">{product.category}</div>
              <h1 className="product-name">{product.name}</h1>
              <p className="product-short-description">
                {product.shortDescription}
              </p>
              <div className="button-group">
                <button 
                  className="btn btn-buynow"
                  onClick={() => {
                    document.getElementById("productFormSection").scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Buy Now
                </button>
                <button 
                  className="btn btn-product-site"
                  onClick={() => window.open(product.productUrl, "_blank")}
                >
                  Take to Product Site
                </button>
              </div>
            </div>
          </div>

          <div className="product-tabs">
            <div className="tab-buttons">
              <button
                className={`tab-button ${currentTab === "description" ? "active" : ""}`}
                onClick={() => setCurrentTab("description")}
              >
                Description
              </button>
              <button
                className={`tab-button ${currentTab === "specifications" ? "active" : ""}`}
                onClick={() => setCurrentTab("specifications")}
              >
                Specifications
              </button>
            </div>

            <div className="tab-content">
              {currentTab === "description" ? (
                <div 
                  className="description-content"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                />
              ) : (
                <div className="specifications-content">
                  <table className="specifications-table">
                    <tbody>
                      {Object.entries(product.specifications).map(([key, value]) => (
                        <tr key={key}>
                          <th>{key}</th>
                          <td>{value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="productFormSection" className="product-form-section">
        <div className="container">
          <h2>Get in Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="ProductName">Product Name</label>
              <input
                type="text"
                id="ProductName"
                name="ProductName"
                value={product.name}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="CustomerName">Your Name*</label>
              <input
                type="text"
                id="CustomerName"
                name="CustomerName"
                value={formState.CustomerName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="PhoneNumber">Phone Number*</label>
              <input
                type="tel"
                id="PhoneNumber"
                name="PhoneNumber"
                value={formState.PhoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-submit">
              Submit Request
            </button>
          </form>
          {formState.submitted && (
            <div className="success-message">
              Thank you for your interest! We will contact you shortly.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
