import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Landing from "routes/Landing";
import AgriFarm from "containers/AgriFarm/index";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import AboutUs from "containers/AboutUs";
import BlogPage from "containers/BlogPage/index";
import BlogArticles from "containers/BlogPage/BlogArticles";
import TechSolutions from "containers/TechSolutions/index";
import ContactUS from "containers/ContactUs/index";
import DairyFarm from "containers/DairyFarm/index";
import Careers from "containers/Careers/index";
import Agriculture from "containers/Agriculture/index";
import PrivacyPolicy from "containers/PrivacyPolicy/index";
import Products from "containers/Products/index";
import ProductDetails from "containers/Products/ProductDetails";
import Terms from "containers/Terms/index";

import "styles/common.scss";
import ScrollToTop from "components/ScrollToTop/index";

const AppRoutes = () => {
  const [hamBurger, setHamBurger] = useState(false);

  const location = useLocation();

  return (
    <>
      <Header hamBurger={hamBurger} setHamBurger={setHamBurger} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing hamBurger={hamBurger} />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/agri-farm" element={<AgriFarm />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:id" element={<BlogArticles />} />
        <Route path="/live-stock-farming" element={<TechSolutions />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/dairy-farm" element={<DairyFarm />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/agriculture" element={<Agriculture />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path='/Products' element={<Products />} />
        <Route path='/Products/:productId' element={<ProductDetails />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>

      {location.pathname !== "/" && <Footer />}
    </>
  );
};

export default AppRoutes;
