import "containers/Products/products.scss";

import C6maxProduct from "assets/images/products/c6max-product.png";
import CarbonlitesProduct from "assets/images/products/carbonlites-product.png";
import SoilSpectraProduct from "assets/images/products/soil spectra-product.png";
import SoilHydraProduct from "assets/images/products/soil-hydra-product-image1.png";
import SoilHydraPlusProduct from "assets/images/products/soil-hydra-plus-product-image1.png";
import MilkTrakProduct from "assets/images/products/milktrak-product-image1.png";

import FadeUp from "components/FadeUp/index";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Arrow from "assets/images/Arrow";

const OurProduct = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    ProductName: "C6 Max",
    CustomerName: "",
    PhoneNumber: "",
    latitude: "",
    longitude: "",
    Address: "",
    State: "",
    Country: "",
    Date: new Date().toISOString().split("T")[0],
    submitted: false
  });

  const updateDate = () => {
    setFormState((prevState) => ({
      ...prevState,
      Date: new Date().toISOString()
    }));
  };

  useEffect(() => {
    updateDate();
    // Get current location when the component mounts
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormState({
            ...formState,
            latitude: latitude,
            longitude: longitude
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []); // Run only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formState.CustomerName || !formState.PhoneNumber || !formState.ProductName) {
      alert("Please fill in the details before submitting.");
      return;
    }

    try {
      // Get address details from OpenStreetMap API
      const { latitude, longitude } = formState;
      const addressResponse = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const addressData = await addressResponse.json();

      const { display_name, address } = addressData; // eslint-disable-line camelcase
      const { state, country } = address;

      // Set address details in formState
      setFormState({
        ...formState,
        Address: display_name, // eslint-disable-line camelcase
        State: state,
        Country: country
      });

      // Call your custom API with form data
      const apiResponse = await fetch(
        "https://hook.eu2.make.com/vvbqtdy8xk59ex8i4q2jpev7iga3gc9q",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formState)
        }
      );

      console.log("API Response:", apiResponse);
      setFormState({
        ...formState,
        submitted: true,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const scrollToForm = (productName) => {
    const formSection = document.getElementById("productFormSection");
    formSection.scrollIntoView({ behavior: "smooth" });
  
    setFormState((prevFormState) => ({
      ...prevFormState,
      ProductName: productName,
      submitted: false,
    }));
  };

  const navigateToDetails = (product) => {
    navigate(`/Products/${product.id}`);
  };

  const products = [
    {
      id: "soil-spectra",
      name: "Soil Spectra",
      image: SoilSpectraProduct,
      category: "Soil Testing",
      description: "SOIL SPECTRA 3.0 analyzes soil and provides an instant comprehensive analysis of crucial soil parameters along with a completely customized POP tailored to optimize your crop nutrition. Empowering you to optimize yields, promote sustainable growth, and streamline your cultivation costs."
    },
    {
      id: "milktrak",
      name: "MilkTrak",
      image: MilkTrakProduct,
      category: "Quality Testing",
      description: "MilkTrak Adulterant Test Kit is a comprehensive solution for rapid detection of common adulterants in milk. This easy-to-use kit ensures milk quality and safety by quickly identifying potential adulterants, helping maintain high standards in dairy production and processing."
    },
    {
      id: "soil-hydra",
      name: "Soil Hydra",
      image: SoilHydraProduct,
      category: "Soil Testing",
      description: "Soil Hydra Soil Moisture Sensor provides real-time, accurate soil moisture measurements to optimize irrigation and improve crop water management. This smart sensor helps farmers make data-driven decisions about watering schedules, preventing both over-irrigation and water stress, leading to better crop health and water conservation."
    },
    {
      id: "soil-hydra-plus",
      name: "Soil Hydra+",
      image: SoilHydraPlusProduct,
      category: "Soil Testing",
      description: "Soil Hydra+ 4-in-1 sensor is an advanced soil monitoring solution that measures moisture, temperature, EC, and pH levels simultaneously. Get comprehensive insights into your soil health with this integrated sensor system, enabling precise agricultural decisions for optimal crop growth and soil management."
    },
    {
      id: "c6-max",
      name: "C6 Max",
      image: C6maxProduct,
      category: "Agri Inputs",
      description: "C6 Max is an organic manure which helps to reduce reliance on synthetic fertilizers, minimises soil degradation, promotes a balanced and resilient soil ecosystem. It is a holistic, non-invasive solution with eco-friendly approach to Soil and Crop Management, ensuring sustainable agriculture for a greener future."
    },
    {
      id: "carbonlites",
      name: "Carbonlites",
      image: CarbonlitesProduct,
      category: "Agri Inputs",
      description: "Carbonlites Organic Manure is IMO and FCO Certified and fully organic. It has a balanced blend of macro and micro nutrients essential for plant vitality. It combines nutrient-rich digestate with biomass and organic materials.This enriched formula includes microbial consortia, scientifically proven to enhance soil health and elevate yield quality."
    }
  ];

  return (
    <section className="common product">
      <div className="container">
        <div className="commonHeading">
          <FadeUp>
            <h2>Our Products</h2>
          </FadeUp>
        </div>
        <div className="productContainer">
          {products.map((product) => (
            <div key={product.id} className="eachProduct">
              <img src={product.image} alt={product.name} />
              <div className="product-content">
                <div className="agri">
                  <span className="agriInputs">{product.category}</span>
                </div>
                <div className="content">
                  <p>
                    <span>{product.name}</span>
                  </p>
                  <p>{product.description}</p>
                </div>
                <div className="button-group">
                  <button
                    className="btn btn-buynow"
                    onClick={() => scrollToForm(product.name)}
                  >
                    Buy Now <Arrow />
                  </button>
                  <button
                    className="btn btn-buynow btn-details"
                    onClick={() => navigateToDetails(product)}
                  >
                    More Details <Arrow />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div id="productFormSection" className="productForm">
          {formState.submitted ? (
            <div className="productFormHeading">
              <span>Form submitted successfully! We will get back to you as soon as possible</span>
            </div>
          ) : (
            <form className="conversationContactForm" onSubmit={handleSubmit}>
              <div className="productFormHeading">
                <span>Start a conversation</span>
                <p>Let us know your requirements and we will get back to you as soon as possible</p>
              </div>
              <div className="conversationContactFormInput">
                <div>
                  <label>
                    Your Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    name="CustomerName"
                    value={formState.CustomerName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <div className="phoneInput">
                    <input
                      type="number"
                      placeholder="Enter your Phone Number"
                      name="PhoneNumber"
                      value={formState.PhoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label>
                    Select Product <span>*</span>
                  </label>
                  <div>
                    <select
                      name="ProductName"
                      value={formState.ProductName}
                      onChange={handleChange}
                    >
                      {products.map((product) => (
                        <option key={product.id} value={product.name}>{product.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="conversationContactFormTextArea">
                <p>
                  <span>*</span>Kindly mark the fields with this symbol to proceed
                  further
                </p>
              </div>
              <div className="conversationContactFormButtons">
                <button type="submit">Submit</button>
              </div>
            </form>
          )}
        </div>

        <div>{}</div>
      </div>
    </section>
  );
};

export default OurProduct;
